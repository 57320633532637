import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/sign-in',
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/tables',
		name: 'Tables',
		layout: "dashboard",
		component: () => import('../views/Tables.vue'),
	},
	{
		path: '/products',
		name: 'Products',
		layout: "dashboard",
		component: () => import('../views/Products.vue'),
	},
	{
		path: '/productedit',
		name: 'Edit product',
		layout: 'dashboard',
		component: () => import('../views/ProductEdit.vue'),
		props: route=> ({productid: route.query.productid})
	},
	{
		path: '/productdetail',
		name: 'Product detail',
		layout: 'dashboard',
		component: () => import('../views/ProductDetail.vue'),
		props: route=> ({productid: route.query.productid})
	},
	{
		path: '/productaisuggestion',
		name: 'Product suggestions',
		layout: 'dashboard',
		component: () => import('../views/ProductAISuggestion.vue'),
		props: route=> ({productid: route.query.productid})
	},
	{
		path: '/addurl',
		name: 'Add Competitor Url',
		layout: 'dashboard',
		component: () => import('../views/ProductUrl.vue'),
		props: route=> ({productid: route.query.productid})
	},
	{
		path: '/competitors',
		name: 'Competitors',
		layout: "dashboard",
		component: () => import('../views/Competitors.vue'),
	},
	{
		path: '/competitoredit',
		name: 'Edit Competitor',
		layout: 'dashboard',
		component: () => import('../views/CompetitorEdit.vue'),
		props: route => ({competitorid: route.query.competitorid})
	},
	{
		path: '/goals',
		name: 'Goals',
		layout: "dashboard",
		component: () => import('../views/Goal.vue'),
	},
	{
		path: '/goaledit',
		name: 'Edit goal',
		layout: "dashboard",
		component: () => import('../views/GoalEdit.vue'),
		props: route => ({goalid: route.query.goalid,categoryid:route.query.categoryid,goaltype:route.query.type})
	},
	{
		path: '/goaldetail',
		name: 'Goal detail',
		layout: 'dashboard',
		component: () => import('../views/GoalDetail.vue'),
		props: route => ({goalid: route.query.goalid})
	},
	{
		path: '/goalactionedit',
		name: 'Edit Action For Goal',
		layout: 'dashboard',
		component: () => import('../views/GoalActionEdit.vue'),
		props: route => ({actionid:route.query.actionid})
	},
	{
		path: '/actions',
		name: 'Actions for goal',
		layout: "dashboard",
		component: () => import('../views/Action.vue'),
	},
	{
		path: '/actionsselector',
		name: 'Select actions',
		layout: 'dashboard',
		component: () => import('../views/Actionselector.vue'),
		props: route => ({goalid:route.query.goalid})
	},
	{
		path: '/categories',
		name: 'Categories',
		layout: "dashboard",
		component: () => import('../views/Categories.vue'),
	},
	{
		path: '/categoryedit',
		name: 'Edit category',
		layout: 'dashboard',
		component: () => import('../views/CategoryEdit.vue'),
		props: route => ({categoryid: route.query.categoryid})
	},
	{
		path: '/categorydetail',
		name: 'Category detail',
		layout: 'dashboard',
		component: () => import('../views/CategoryDetail.vue'),
		props: route => ({categoryid: route.query.categoryid})
	},
	{
		path: '/productsselector',
		name: 'Select products',
		layout: 'dashboard',
		component: () => import('../views/ProductsSelector.vue'),
		props: route => ({categoryid: route.query.categoryid})
	},
	{
		path: '/Profile',
		name: 'Profile',
		layout: "dashboard",
		
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/Organisation',
		name: 'Organisation',
		layout: "dashboard",
		
		component: () => import('../views/Organisation.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		component: () => import('../views/Sign-Up.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

export default router
