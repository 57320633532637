import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        product :{
            addurl :{
                validate: 'Validate',
                validating: 'Validating...',
                description: 'Adding URL for the specific product URL from competitor'
            }
        },
        goal: {
            edit:{
                description: 'Edit goal information',
                label:{
                    kpi:'Goal Category',
                    relationship: 'Condition'
                }
            }
        }
    },
};

export const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});