/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import { Layout, Button, Form, Row,Col, Switch, Input, Collapse, Menu, Card, Avatar, Radio, Space, Tag, Drawer, Statistic, Timeline, Table, List, Dropdown, Breadcrumb, Progress, Badge, Descriptions, Upload, Icon, Checkbox, DatePicker, Affix, Divider, Select, Tabs} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import SettingsLayout from './layouts/Settings.vue'
import router from './router'
import { VueAxios } from './utils/request'
import store from './store/'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons'
import { faSpinner, faTags, faChartLine, faList, faFlag, faSitemap, faUser, faClock, faPen, faLock, faDoorClosed, faBars, faPlus, faPlug, faPaperPlane, faStar, faUsersCog} from '@fortawesome/free-solid-svg-icons'
import {i18n} from '@/plugins/i18n';

library.add(faSpinner, faChartLine, faList, faTags, faStar,faUsersCog,
  faSitemap, faFlag, faUser, faClock, faPen, faLock, faDoorClosed, faBars, faPlus, faPlug, faPaperPlane);

import './scss/app.scss';

library.add(faGoogle,faMicrosoft)

Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-settings", SettingsLayout);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(Layout);
Vue.use(Button);
Vue.use(Form);
Vue.use(Row);
Vue.use(Col);
Vue.use(Switch);
Vue.use(Input);
Vue.use(Collapse);
Vue.use(Menu);
Vue.use(Card);
Vue.use(Radio);
Vue.use(Avatar);
Vue.use(Tag);
Vue.use(Drawer);
Vue.use(Statistic);
Vue.use(Timeline);
Vue.use(Table);
Vue.use(List);
Vue.use(Dropdown);
Vue.use(Breadcrumb);
Vue.use(Progress);
Vue.use(Space);
Vue.use(Badge);
Vue.use(Descriptions);
Vue.use(Upload);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(store);
Vue.use(DatePicker);
Vue.use(Affix);
Vue.use(Divider);
Vue.use(Select);
Vue.use(Tabs);
Vue.use(VueAxios);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')